import { css } from '@emotion/react';
import responsive from './responsive';

const _fontStep = (paragraphFactor, headingFactor) => ({
    paragraphFactor,
    headingFactor,
});

const fontConfig = {
    paragraph: {
        family: 'nb_international, sans-serif',
    },
    mono: {
        family: 'nb_international_mono',
    },
    heading: {
        family: 'coffee_collective, sans-serif',
        beginSize: 24,
    },
    steps: {
        0: _fontStep(1, 1),
        10: _fontStep(1, 1),
        20: _fontStep(1, 1),
        30: _fontStep(1, 1),
        40: _fontStep(1, 1),
    },
};

const size = baseSize => {
    // Get current factor
    const factor =
        baseSize < fontConfig.heading.beginSize
            ? 'paragraphFactor'
            : 'headingFactor';

    // Get font sizess for each breakpoint
    const fontSize = responsive.breakpoints.reduce((acc, breakpoint) => {
        // Does config exist for current breakpoint (it really should)?
        if (fontConfig.steps[breakpoint.label]) {
            // Add to accumulator
            acc = [
                ...acc,
                fontConfig.steps[breakpoint.label][factor] * baseSize + 'px',
            ];
        }

        // Return
        return acc;
    }, []);

    // Return
    return responsive.mediaQueries({
        fontSize,
    });
};

const fontStyle = (fontSizes, lineHeights, familiy, weight = 'normal') => {
    const mappedLineHeights = Object.keys(lineHeights).reduce((acc, key) => {
        acc = { ...acc, [key]: lineHeights[key] / fontSizes[key] };
        return acc;
    }, {});

    return css`
        ${responsive.property('font-size', fontSizes)};
        ${responsive.property('line-height', mappedLineHeights)};
        font-family: ${fontConfig[familiy].family};
        font-weight: ${weight};
        color: currentColor;
    `;
};

const fontStyles = {
    h1: css`
        ${fontStyle(
            { 0: 42, 10: 54, 20: 108 },
            { 0: 48, 10: 58, 20: 118 },
            'heading'
        )};
    `,
    h2: css`
        ${fontStyle({ 0: 24, 20: 56 }, { 0: 34, 20: 74 }, 'paragraph', 600)};
    `,
    h3: css`
        ${fontStyle({ 0: 24, 20: 56 }, { 0: 34, 20: 74 }, 'paragraph', 300)};
    `,
    h4: css`
        ${fontStyle({ 0: 24, 20: 64 }, { 0: 36, 20: 88 }, 'heading')};
    `,
    h5: css`
        ${fontStyle(
            { 0: 24, 20: 38, 30: 44 },
            { 0: 36, 20: 56, 30: 64 },
            'heading'
        )};
    `,
    h6: css`
        ${fontStyle({ 0: 16, 30: 32 }, { 0: 24, 30: 42 }, 'heading')};
    `,
    h7: css`
        ${fontStyle({ 0: 20, 20: 32 }, { 0: 30, 20: 46 }, 'paragraph', 600)};
    `,
    h8: css`
        ${fontStyle(
            { 0: 20, 30: 22, 40: 32 },
            { 0: 30, 30: 36, 40: 46 },
            'paragraph',
            300
        )};
    `,
    h9: css`
        ${fontStyle({ 0: 24 }, { 0: 36 }, 'heading')};
    `,
    h10: css`
        ${fontStyle({ 0: 20, 20: 24 }, { 0: 30, 20: 36 }, 'paragraph', 300)};
    `,
    h105: css`
        ${fontStyle({ 0: 22, 20: 24 }, { 0: 32, 20: 36 }, 'paragraph', 300)};
    `,
    h11: css`
        ${fontStyle({ 0: 16, 40: 20 }, { 0: 24, 40: 30 }, 'paragraph')};
    `,
    h12: css`
        ${fontStyle({ 0: 18 }, { 0: 28 }, 'paragraph')};
    `,
    h13: css`
        ${fontStyle({ 0: 12, 20: 18 }, { 0: 20, 20: 28 }, 'mono')};
        text-transform: uppercase;
    `,
    h14: css`
        ${fontStyle({ 0: 12, 20: 16 }, { 0: 20, 20: 24 }, 'paragraph', 600)};
    `,
    h15: css`
        ${fontStyle(
            { 0: 12, 20: 16, 30: 14, 40: 16 },
            { 0: 20, 20: 24, 30: 22, 40: 24 },
            'paragraph'
        )};
    `,
    h16: css`
        ${fontStyle(
            { 0: 12, 20: 14, 30: 12, 40: 14 },
            { 0: 18, 20: 18, 30: 18, 40: 18 },
            'heading'
        )};
        letter-spacing: 0.1em;
        text-transform: uppercase;
    `,
    h17: css`
        ${fontStyle(
            { 0: 8, 20: 12, 30: 14 },
            { 0: 14, 20: 16, 30: 20 },
            'mono'
        )};
        text-transform: uppercase;
    `,
    h17tiny: css`
        ${fontStyle({ 0: 8, 20: 10 }, { 0: 14, 20: 14 }, 'mono')};
        text-transform: uppercase;
    `,
    h18: css`
        ${fontStyle({ 0: 10, 20: 12 }, { 0: 14, 20: 16 }, 'paragraph')};
    `,
    h19: css`
        ${fontStyle({ 0: 12, 20: 14 }, { 0: 17, 20: 20 }, 'mono')};
        text-transform: uppercase;
    `,
    h3Responsive: css`
        ${fontStyle({ 0: 24, 20: 32 }, { 0: 34, 20: 46 }, 'paragraph', 300)};
    `,
    h3ShopQuickLink: css`
        ${fontStyle(
            { 0: 24, 20: 32, 30: 42, 40: 56 },
            { 0: 34, 20: 40, 30: 50, 40: 74 },
            'paragraph',
            300
        )};
    `,
    paragraph: css`
        ${fontStyle({ 0: 8, 20: 16 }, { 0: 14, 20: 16 }, 'paragraph', '600')};
        letter-spacing: 0.05em;
    `,
};
const style = style => {
    return fontStyles[style];
};

export default {
    size,
    style,
    config: fontConfig,
};
